import React from "react"
import Layout from "../components/Layout"
import { Helmet } from 'react-helmet'

export default function Home() {
  return (
    <><Helmet>
      <script type="text/javascript">
      {`(function(m,a,i,l,e,r){ m['MailerLiteObject']=e;function f(){
      var c={ a:arguments,q:[]};var r=this.push(c);return "number"!=typeof r?r:f.bind(c.q);}
      f.q=f.q||[];m[e]=m[e]||f.bind(f.q);m[e].q=m[e].q||f.q;r=a.createElement(i);
      var _=a.getElementsByTagName(i)[0];r.async=1;r.src=l+'?v'+(~~(new Date().getTime()/1000000));
      _.parentNode.insertBefore(r,_);})(window, document, 'script', 'https://static.mailerlite.com/js/universal.js', 'ml');
      
      var ml_account = ml('accounts', '1749828', 'i6t9j5r8v3', 'load');`}
      </script>
    </Helmet>
    <Layout>
      <div className="container has-background-primary p-6">
        <h1 className="title has-text-black">Missouri to California in 1865...</h1>
        <p className="subtitle pl-5 pt-3">via the Great Platte River Road</p>
        <p className="title has-text-black pt-5">and back to Missouri in 1868...</p>
        <p className="subtitle pl-5 pt-3">via the Butterfield Overland Mail Route</p>
        <p className="title has-text-black pt-5">chronicled by Ruth Shackelford in her diary.</p>
        <p className="subtitle pl-5 pt-3">mapped and put into context.</p>
        <div className="ml-form-embed pt-6"
          data-account="1749828:i6t9j5r8v3"
          data-form="4991978:h4o1a0">
        </div>
      </div>
    </Layout></>
  ) 
}
